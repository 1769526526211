import React from "react";
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DeviceElement } from "../../orm/dto";

interface DeviceElementProps {
    element: DeviceElement
}

const TableCaption = ({children}: any) => (
    <Typography variant="h6" sx={{mt: 2, textAlign: 'center'}}>{children}</Typography>
)

const ElementInfoTableCell = styled(TableCell)({
    borderBottom: 'none'
})

export default function DeviceElementView({element}: DeviceElementProps) {

    return (<>
        <Table sx={{width: 'initial'}} size="small">
            <TableBody>
                <TableRow>
                    <ElementInfoTableCell>ObjectId:</ElementInfoTableCell>
                    <ElementInfoTableCell>{element.objectId}</ElementInfoTableCell>
                </TableRow>
                {element.designator && (
                    <TableRow>
                        <ElementInfoTableCell>Designator:</ElementInfoTableCell>
                        <ElementInfoTableCell>{element.designator}</ElementInfoTableCell>
                    </TableRow>
                )}
                <TableRow>
                    <ElementInfoTableCell>Type:</ElementInfoTableCell>
                    <ElementInfoTableCell>{element.type}</ElementInfoTableCell>
                </TableRow>
                <TableRow>
                    <ElementInfoTableCell>Number:</ElementInfoTableCell>
                    <ElementInfoTableCell>{element.number}</ElementInfoTableCell>
                </TableRow>
            </TableBody>
        </Table>
        {element.dpds.length > 0 && (<>
            <TableCaption>Processes Data</TableCaption>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>DDI</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Trigger Methods</TableCell>
                        <TableCell>Property</TableCell>
                        <TableCell>Unit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {element.dpds.map(dpd => (
                        <TableRow key={dpd.ddi}>
                            <TableCell>
                                <Box>{dpd.ddi} (0x{dpd.ddiHex})</Box>
                                <Box>{dpd.ddiName || 'Unknown DDI'}</Box>
                            </TableCell>
                            <TableCell>{dpd.designator}</TableCell>
                            <TableCell>{dpd.triggerMethods.join(', ')}</TableCell>
                            <TableCell>{dpd.property.join(', ')}</TableCell>
                            <TableCell>{dpd.unit}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>)}
        {element.dpts.length > 0 && (<>
            <TableCaption>Properties</TableCaption>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>DDI</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Unit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {element.dpts.map(dpt => (
                        <TableRow key={dpt.ddi}>
                            <TableCell>
                                <Box>{dpt.ddi} (0x{dpt.ddiHex})</Box>
                                <Box>{dpt.ddiName || 'Unknown DDI'}</Box>
                            </TableCell>
                            <TableCell>{dpt.designator}</TableCell>
                            <TableCell>{'value' in dpt ? `${dpt.value} (raw: ${dpt.rawValue})` : dpt.rawValue}</TableCell>
                            <TableCell>{dpt.unit}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>)}
    </>)
}