import { Grid, Card, CardContent } from "@mui/material";

interface InfoBoxProps {
  children: any;
}
export default function InfoBox({ children }: InfoBoxProps) {
    return (
        <Grid item container direction='column'  justifyContent='flex-end' sx={{height:1, width:4/10, marginLeft: 'auto'}}>
            <Grid container item justifyContent='flex-end'  sx={{height:1}}>
                <Card variant="outlined" style={{ minWidth: '480px', minHeight: '100%' }}  sx={{height:8/10}}>
                    <CardContent>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            {children}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
