import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

import { store } from "./state/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
if( container ){

  const root = createRoot(container); // Create a root.

  root.render( // Use the render method on the root
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
