import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useCallback, useState } from "react";

export class BasicDateTimePickerProps{
    public onDateChanged:any
    public date:Date
    public disabled:any

    constructor( _date:Date, _onDateChanged:any, _disabled:boolean){
        this.onDateChanged = _onDateChanged
        this.date = _date
        this.disabled = _disabled
    }
}


export function BasicDateTimePicker( props:BasicDateTimePickerProps) {
    const [date,setDate] = useState<Date>()

    const onDateChange=  useCallback((date:any) => {
        var nextDate = new Date(date)
        props.onDateChanged(nextDate)
        setDate(nextDate)        
    }, [props.onDateChanged]);

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker']}>
          <DateTimePicker disabled={props.disabled} label="Basic date time picker" onChange={onDateChange} value={date} />
        </DemoContainer>
      </LocalizationProvider>
    );
  }