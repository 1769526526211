import { Box, Button, CircularProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useCallback } from "react";
import { analyzeDevice, convertDeviceToDDOP, ResponseErrorWithMessages } from "../../orm/dev4ag";
import { DeviceAnalysisResult, ResponseWithMessages } from "../../orm/dto";
import FeedbackButton from "./ShareDataButton";
import { margin } from "@mui/system";

interface DeviceInputProps {
  deviceXML: string;
  onDeviceXMLChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onAnalysisResponseChange: (
    result: ResponseWithMessages<DeviceAnalysisResult> | null
  ) => void;
}

export default function DeviceInput({
  deviceXML,
  onDeviceXMLChange,
  onAnalysisResponseChange,
}: DeviceInputProps) {
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [convertVersion, setConvertVersion] = React.useState<number>(3);

  const onAnalyzeClick = useCallback(async () => {
    setFetching(true);
    onAnalysisResponseChange(null);
    try {
      const analysisResponse = await analyzeDevice(deviceXML, true, true, true);
      setFetching(false);
      onAnalysisResponseChange(analysisResponse);
    } catch (error) {
      setFetching(false);
      onAnalysisResponseChange({
        messages: (error as ResponseErrorWithMessages).responseMessages,
      });
    }
  }, [deviceXML, onAnalysisResponseChange]);

  const onConvertClick = useCallback( async() =>{
    setFetching(true);
    onAnalysisResponseChange(null);
    try {
      const analysisResponse = await convertDeviceToDDOP(deviceXML,convertVersion);
      setFetching(false);
      //onConvertedResponse(analysisResponse);
    } catch (error) {
      setFetching(false);
      onAnalysisResponseChange({
        messages: (error as ResponseErrorWithMessages).responseMessages,
      });
    }
  }, [deviceXML, onAnalysisResponseChange,convertVersion]);

  const handleSelectConvertVersion = (event: SelectChangeEvent<number>) => {
    console.log("Handle Select:" + JSON.stringify(event.target.value as number));
    setConvertVersion(event.target.value as number);
  };

  return (
    <>
      <Box display="block" flexDirection="column" sx={{ height:'400px'}} p={2}>
        <TextField
          autoFocus
          label="Device Description XML"
          placeholder="Enter ISOXML device description as XML"
          multiline
          fullWidth
          rows={1}
          value={deviceXML}
          onChange={onDeviceXMLChange}
          sx={{ height:1 }}
          InputProps={{ sx: { height: "100%" } }}
          inputProps={{ style: { height: "100%" } }}
        />
        <Box sx={{ display: "flex", mt: 2 ,mb:2 }}>
          <Button
            size="large"
            variant="contained"
            disabled={fetching}
            onClick={onAnalyzeClick}
          >
            Analyze Device
            {fetching && (
              <CircularProgress
                size={30}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-15px",
                  marginLeft: "-15px",
                }}
              />
            )}
          </Button>
          <Box sx={{ flexGrow: 1, ml: 2,  textAlign: "right" }}>
            <Typography component="span" sx={{ mr: 1 }}>Export Version:</Typography>
            <Box  component="span" sx={{ mr: 2 }}>
              <Select value={convertVersion} onChange={handleSelectConvertVersion} title="Version">
                <MenuItem key="3" value="3">3</MenuItem>
                <MenuItem key="4" value="4">4</MenuItem>
              </Select>
            </Box>
            <Button size="large" variant="contained" disabled={fetching} onClick={onConvertClick}>
              Convert to Binary
            </Button>
            
          </Box>
        </Box>
      </Box>
    </>
  );
}
