import env from "@beam-australia/react-env";
import { AuthConfig } from "./auth-service";

const authConfig: AuthConfig = {
  url: env("AUTH_URL") ?? "",
  realm: env("AUTH_REALM") ?? "",
  clientId: env("AUTH_CLIENT_ID") ?? "",
};

export default authConfig;
