import { Box } from "@mui/material";


export default function DateTimeUtilsHelp() {
    return (
        <Box>
            <h1>What are these Time & Date Utils</h1>
            ISOXML as well as other Telemetry Date Exchange formats handle TimeStamps that consist of Date and Time.<br/>
            In an ISOXML Binary File, there is e.g. a construct of 6 Bytes to represent Date & Time.<br/> 
            When debugging such data, it's always hard to understand, when the dataset was actually recorded.<br/>
            These Tools help to display the clear Date & Time without any need to convert the whole dataset.
        </Box>
    );
}
