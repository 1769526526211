import React, { useMemo } from "react";
import { Alert, Box } from "@mui/material";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Graph from 'react-graph-vis'

import { DeviceHierarchyNode, DeviceHierarchyRoot } from "../../orm/dto";
import GraphLegend, { COLOR_BY_ELEMENT_TYPE } from "./GraphLegend";

const BASE_OPTIONS = {
    interaction: {
        dragNodes: false,
        selectable: false
    },
    layout: {
        hierarchical: {
            enabled: true,
            sortMethod: 'directed',
            shakeTowards: 'roots',
            edgeMinimization: false,
            blockShifting: false
        }
    },
    physics: {
        enabled: false
    },
    nodes: {
        shape: 'image',
        shapeProperties: {
            useImageSize: true
        }
    },
    edges: {
        smooth: {
            type: 'horizontal',
            roundness: 1
        }
    }
}

type SVGGenerator = (width?: number, height?: number) => string

function wrapHTMLinSVG(html: string, width?: number, height?: number): string {
    const widthAttr = width ? `width="${width}"` : ''
    const heightAttr = height ? `height="${height}"` : ''
    return `
        <svg xmlns="http://www.w3.org/2000/svg" ${widthAttr} ${heightAttr}>
            <style>
                .graph-node {
                    font: initial;
                    letter-spacing: initial;
                    background-color: #BFD878;
                    display: inline-block;
                    border-radius: 4px;
                    border: 1px solid grey;
                    padding: 8px;
                }

                .graph-node * {
                    white-space: nowrap;
                }

                .graph-node__title {
                    text-align: center;
                    font-weight: bold;
                }

                .graph-node__type {
                    text-align: center;
                    font-style: italic;
                    font-size: 80%;
                    text-transform: capitalize;
                }

                .graph-node__objects-wrapper {
                    margin-top: 16px;
                }
            </style>
            <foreignObject width="100%" height="100%">
                ${html}
            </foreignObject>
        </svg>
    `
}

function element2html(element: DeviceHierarchyNode): string {
    const title = element.elementName || `Element ${element.elementId}`
    const objects = [...element.dpds, ...element.dpts].map(obj => `<div>${obj}</div>`)

    return `
        <span
            xmlns="http://www.w3.org/1999/xhtml"
            class="graph-node"
            style="background-color: ${COLOR_BY_ELEMENT_TYPE[element.elementType]}"
        >
            <div class="graph-node__title">
                ${title}
            </div>
            <div class="graph-node__type">
                ${element.elementType}
            </div>
            <div class="graph-node__objects-wrapper">
                ${objects.join('')}
            </div>
        </span>
    `
}

function device2html(root: DeviceHierarchyRoot): string {
    const title = root.designator || 'Device'

    return `
        <span
            xmlns="http://www.w3.org/1999/xhtml"
            class="graph-node"
            style="background-color: ${COLOR_BY_ELEMENT_TYPE['device']}"
        >
            <div class="graph-node__title">${title}</div>
        </span>
    `
}

function svgToGraphNode(svgGenerator: SVGGenerator, id: number) {
    var div = document.createElement('div')
    div.innerHTML = svgGenerator()
    document.body.appendChild(div)
    const span = div.querySelector('span') as HTMLSpanElement
    const width = span.offsetWidth
    const height = span.offsetHeight
    document.body.removeChild(div)
    const svg = svgGenerator(width, height)

    var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)

    return {
        graphNode: {
            id,
            image: url
        },
        size: {width, height}
    }
}

function GraphRenderingError({error}: FallbackProps) {
    return (
        <Box sx={{mt: 2}}>
            <Alert severity="error">Error during hierarchy graph rendering: "{error.message}"</Alert>
            <Alert sx={{mt: 1}} severity="info">Please check the Report tab for possible issues</Alert>
        </Box>
    )
}

interface DeviceOverviewGraphParams {
    overviewGraph: DeviceHierarchyRoot
}

export default function DeviceOverviewGraph({overviewGraph}: DeviceOverviewGraphParams) {
    const graphData = useMemo(() => {

        const nodes: any[] = []
        const edges: any[] = []

        const widthsByLevel: {[level: number]: number[]} = {}
        const maxHeightByLevel: {[level: number]: number}  = {}

        const processNode = (node: DeviceHierarchyNode, level: number): void => {
            const {graphNode, size} = svgToGraphNode((width?: number, height?: number) => {
                return wrapHTMLinSVG(element2html(node), width, height)
            }, node.elementId)

            nodes.push(graphNode)

            widthsByLevel[level] = [...(widthsByLevel[level] || []), size.width]
            maxHeightByLevel[level] = Math.max(maxHeightByLevel[level] || 0, size.height)

            node.children.forEach(child => {
                edges.push({from: node.elementId, to: child.elementId})
            })
            node.children.forEach(child => processNode(child, level + 1))
        }
        const processRoot = (root: DeviceHierarchyRoot): void => {
            const {graphNode, size} = svgToGraphNode((width?: number, height?: number) => {
                return wrapHTMLinSVG(device2html(root), width, height)
            }, 0)

            nodes.push(graphNode)

            widthsByLevel[0] = [size.width]
            maxHeightByLevel[0] = size.height

            root.elements.forEach(child => {
                edges.push({from: 0, to: child.elementId})
            })
            root.elements.forEach(child => processNode(child, 1))
        }


        processRoot(overviewGraph)

        const maxNodeSeparationByLevel = Object.keys(widthsByLevel).map(level => {
            const widths = widthsByLevel[level as any].sort((a, b) => b - a)

            if (widths.length > 1) {
                return (widths[0] + widths[1]) / 2
            } else {
                return 0
            }
        })

        const levelSeparationByLevel = Object.keys(maxHeightByLevel).map((level: any) => {
            const curHeight = maxHeightByLevel[level]
            const nextHeight = maxHeightByLevel[level + 1] || 0
            return (curHeight + nextHeight) / 2 + 100
        })

        const graphOptions = {
            ...BASE_OPTIONS,
            layout: {
                ...BASE_OPTIONS.layout,
                hierarchical: {
                    ...BASE_OPTIONS.layout.hierarchical,
                    nodeSpacing: Math.max(...maxNodeSeparationByLevel) + 16,
                    levelSeparation: Math.max(...levelSeparationByLevel) + 64
                }
            }
        }

        return {
            graph: {nodes, edges},
            graphOptions
        }

    }, [overviewGraph])

    const availableTypes = useMemo(() => {
        const types = new Set<string>(['device'])
        const processNode = (node: DeviceHierarchyNode) => {
            types.add(node.elementType)
            node.children.forEach(processNode)
        }

        overviewGraph.elements.forEach(processNode)

        return Array.from(types)
    }, [overviewGraph])

    return (
        <ErrorBoundary FallbackComponent={GraphRenderingError}>
            <GraphLegend types={availableTypes}/>
            <Graph
                graph={graphData.graph}
                options={graphData.graphOptions}
                style={{height: '100%'}}
            ></Graph>
        </ErrorBoundary>
    )
}