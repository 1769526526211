import {  Box } from "@mui/material";


export default function Shp2ISOXMLHelp() {
    return (
        <Box>
            <h1>What is Shape2ISOXML?</h1>
            Shape2ISOXML is an API to convert Shape Data into ISOXML.
            <h1>What is this tool?</h1>
            This tool communicates with the shp2ISOXML API via the dev4AgTools BackEnd. 
            It offers the possibility to test the shp2ISOXML API before integrating the API.

            <b>Developers Remark:</b> This UI communicates with the dev4AgTools BackEnd, not the shp2ISOXML-API directly. Therefore it uses a different API structure.
        </Box>
    );
}
