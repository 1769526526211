import React, { FormEvent, useCallback, useState } from "react"
import {styled,Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, TextField, Typography} from "@mui/material"

import { buildClientName, parseClientName, ResponseErrorWithMessages } from "../orm/dev4ag"
import { ResponseMessage, ClientNameFields, ClientNameParseResult } from "../orm/dto"
import { CheckboxRow, InputRow } from "./utils"
import ResponseMessages from "./ResponseMessages"
import InfoBox from "./Common/InfoBox"
import ClientNameHelp from "./help/ClientNameHelp"

const INIT_CLIENT_NAME_FIELDS = {
    serialNo: 0,
    manufacturerCode: 0,
    ecuInstance: 0,
    functionInstance: 0,
    deviceClass: 0,
    deviceClassNo: 0,
    industryGroup: 0,
    selfConfigurable: false,
    deviceClassInstance: 0,
    function: 0
}

const ClientNameTableCell = styled(TableCell)({
    borderBottom: 'none',
    textAlign : 'left',
    alignContent: 'left',
    marginLeft : '1em',
})

const ClientNameRow= ({title, value, showEmpty}: any) => 
value || showEmpty ? (
    <TableRow>
        <ClientNameTableCell>{title}</ClientNameTableCell>
        <ClientNameTableCell>{value}</ClientNameTableCell>
    </TableRow>
) : null


export default function ClientName() {
    const [clientNameString, setClientNameString] = useState<string>('')
    const [clientNameFields, setClientNameFields] = useState<ClientNameFields>(INIT_CLIENT_NAME_FIELDS)

    const [responseMessages, setResponseMessages] = useState<ResponseMessage[] | null>(null)
    const [fetching, setFetching] = useState<boolean>(false)
    const [fetchingText, setFetchingText] = useState<string>("")
    const [parseResult, setParseResult] = useState<ClientNameParseResult | null>(null)

    const onStringInputChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        setClientNameString((event.target as HTMLInputElement).value)
    }, [])

    const onParseFromString = useCallback(async () => {
        setFetching(true)
        setFetchingText("Parsing")
        setParseResult(null)
        setResponseMessages(null)
        try {
            const parseResponse = await parseClientName(clientNameString)
            setParseResult(parseResponse.result || null)
            const result = parseResponse.result

            if (result) {
                setClientNameFields({
                    serialNo: result.serialNo,
                    manufacturerCode: result.manufacturerCode,
                    ecuInstance: result.ecuInstance,
                    functionInstance: result.functionInstance,
                    deviceClass: result.deviceClass,
                    deviceClassNo: result.deviceClassNo,
                    industryGroup: result.industryGroup,
                    selfConfigurable: result.selfConfigurable,
                    deviceClassInstance: result.deviceClassInstance,
                    function: result.function
                })
            }
        } catch (error) {
            setResponseMessages((error as ResponseErrorWithMessages).responseMessages)
        } finally {
            setFetching(false)
        }
    }, [clientNameString])

    const onReverseString = useCallback(async () => {
        let revertString = ""
        let input = clientNameString
        while( input.length >= 2){
            revertString = input[0] + input[1] + revertString;
            input = input.substring(2);
        }
        setClientNameString(revertString)
    }, [clientNameString])

    const onParseFromFields = useCallback(async () => {
        setFetching(true)
        setFetchingText("Building...")
        setParseResult(null)
        setResponseMessages(null)
        try {
            const parseResponse = await buildClientName(clientNameFields)
            setParseResult(parseResponse.result || null)
            parseResponse.result && setClientNameString(parseResponse.result.name)
        } catch (error) {
            setResponseMessages((error as ResponseErrorWithMessages).responseMessages)
        } finally {
            setFetching(false)
        }
    }, [clientNameFields])

    const onNumberKeyPress = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onParseFromString()
        }
    }, [onParseFromString])

    const onFieldInputChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        const attributeName = target.dataset.bindAttribute as keyof ClientNameFields
        const isCheckbox = target.type === 'checkbox'

        if (isCheckbox) {
            (clientNameFields[attributeName] as boolean) = target.checked
        } else {
            const num = parseInt(target.value)
            if (isNaN(num)) {
                (clientNameFields[attributeName] as any) = null
            } else {
                (clientNameFields[attributeName] as number) = parseInt(target.value)
            }
        }
        setClientNameFields({...clientNameFields})
    }, [clientNameFields])

    const onCopyClick = useCallback(() => {
        navigator.clipboard.writeText(JSON.stringify(parseResult))
    }, [parseResult])

    return (
        <Grid container wrap="wrap" spacing={2} height={1}>
            <Grid item sm="auto" container direction="column" spacing={2} >
                <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <TextField
                                size="small"
                                autoFocus
                                label="Enter the ClientName as a HEX number"
                                value={clientNameString}
                                onInput={onStringInputChange}
                                onKeyPress={onNumberKeyPress}
                            />
                            <Button
                                onClick={onReverseString}
                            >Revert
                            </Button>
                            <Button
                                variant="contained"
                                onClick={onParseFromString}
                                fullWidth
                                sx={{display: 'block', mt: 2}}
                            >Parse ClientName</Button>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    <InputRow fields={clientNameFields} title='Serial No' attr={'serialNo'} min={0} max={2097151} onChange={onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Manufacturer Code' attr={'manufacturerCode'} min={ 0} max={ 2047} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='ECU instance' attr={'ecuInstance'} min={ 0} max={ 7} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Function instance' attr={'functionInstance'} min={ 0} max={ 31} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Device class' attr={'deviceClassNo'} min={ 0} max={ 63} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Industry group' attr={'industryGroup'} min={ 0} max={ 7} onChange={ onFieldInputChange}/>
                                    <CheckboxRow fields={clientNameFields} title='Self configurable' attr={'selfConfigurable'} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Device class instance' attr={'deviceClassInstance'} min={ 0} max={ 15} onChange={ onFieldInputChange}/>
                                    <InputRow fields={clientNameFields} title='Function' attr={'function'} min={0} max={255} onChange={onFieldInputChange}/>
                                </TableBody>
                            </Table>
                            <Button
                                variant="contained"
                                onClick={onParseFromFields}
                                fullWidth
                                sx={{mt: 2}}
                            >
                                Construct ClientName
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item sm="auto" sx={{flexShrink: {sm: 1}}}>
                {fetching && (
                    <Typography>{fetchingText}</Typography>
                )}
                {responseMessages && (
                    <ResponseMessages messages={responseMessages} />
                )}
                {parseResult && (
                    <Card variant="outlined">
                        <CardContent>
                            <Typography sx={{mb: 2, textAlign: 'center'}}>ClientName parsing results</Typography>
                            <Table size="small"> 
                                <TableBody> 
                                    <ClientNameRow title="Manufacturer" value={parseResult.manufacturer!== undefined ? parseResult.manufacturer.manufacturer : "unknown"}  showEmpty/>
                                    <ClientNameRow title="deviceClass" value={parseResult.deviceClass}  showEmpty/>
                                    <ClientNameRow title="SerialNumber" value={parseResult.serialNo} showEmpty />
                                    <ClientNameRow title="IndustryGroup" value={parseResult.industryGroup}  showEmpty/>
                                    <ClientNameRow title="Function" value={parseResult.function}  showEmpty/>
                                    <ClientNameRow title="selfConfigurable" value={parseResult.selfConfigurable?"True":"False"} showEmpty/>
                                    <ClientNameRow title="deviceClassInstance" value={parseResult.deviceClassInstance}  showEmpty/>
                                    <ClientNameRow title="ecuInstance" value={parseResult.ecuInstance}  showEmpty/>
                                </TableBody>
                            </Table> 
                            <Button 
                                variant="contained"
                                fullWidth
                                color="secondary"
                                onClick={onCopyClick}
                                sx={{mt: 2}}
                            >Copy as JSON</Button> 
                        </CardContent>
                    </Card>
                )} 
            </Grid>
            <InfoBox>
                    <ClientNameHelp/>
            </InfoBox>            
        </Grid>
    )
}