import { Box } from "@mui/material";


export default function ClientNameHelp() {
    return (
        <Box>
            <h1>What is a WorkingSet Mastername</h1>
            The WorkingSet Mastername, also known as ClientName is a unique identifier for a machine
            on an ISOBUS network as well as in an ISOXML TaskSet.

            <h1>What is this tool about?</h1>
            The tool converts the Workingset Mastername into human readable information.

            <h1>How to use it?</h1>
            Copy the Attribute D from an ISOXML DVC-Element and add it into the field
            <h2>How it works</h2>
            <video controls preload="auto" poster="vid/deviceDesrciption_poster.png" width="100%" src="vid/ClientName.webm">
                <img src="vid/clientName_poster.png" alt="How it works" width="100%" height="100%"/>
            </video>
        </Box>
    );
}
