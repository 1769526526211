type Object = {
    [key: string]: any
}

export function buildFormData(formData: FormData, data: Object | Date | File | string | number | boolean, parentKey?: string) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey!, value as string);
    }
}

export async function downloadFileFromResponse(response: Response) {
    const fileName = response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0] || 'ISOXML.zip';
    let url = window.URL.createObjectURL(await response.blob())
    let a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}
