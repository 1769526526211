import { Box } from "@mui/material";


export default function PGNHelp() {
    return (
        <Box>
            <h1> What is a PGN?</h1>
            A PGN is a <b>Parameter Group Number</b> from a J1939 CAN Bus message. 
            A list of such messages can be found at <a href="https://www.isobus.net/isobus/pGNAndSPN/?type=PGN">isobus.net</a>.

            <h1>What can this tool do</h1> 
            This tool was made for people comming across a CAN Bus message with a PGN and not knowing how to accuratly split it
            into its different parts. Simply copy the CANID (such most likely begin with "18") into the textfield and you can parse it.
            <h2>How it works</h2>
            <video controls preload="auto" poster="vid/deviceDesrciption_poster.png" width="100%" src="vid/PGN.webm">
                <img src="vid/deviceDesrciption_poster.png" alt="How it works" width="100%" height="100%"/>
            </video>
        </Box>
    );
}
