import React, { useCallback, useState, FormEvent, KeyboardEvent } from 'react'
import { Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'


import { parsePGN, buildPGN, ResponseErrorWithMessages } from '../orm/dev4ag'
import { CheckboxRow, InputRow } from './utils'

import { PGNFields, PGNParseResult, ResponseWithMessages } from '../orm/dto'
import ResponseMessages from './ResponseMessages'
import InfoBox from './Common/InfoBox'
import PGNHelp from './help/PGNHelp'

const INIT_PGN_FIELDS: PGNFields = {
    priority: 0,
    extendedDataPage: false,
    dataPage: false,
    pduFormat: 0,
    pduSpecific: 0,
    sourceAddress: 0
}

export default function PGN() {
    const [pgnNumber, setPgnNumber] = useState<string>('')
    const [pgnFields, setPgnFields] = useState<PGNFields>(INIT_PGN_FIELDS)

    // const [errorMessage, setErrorMessage] = useState<string>('')
    const [fetching, setFetching] = useState<boolean>(false)
    const [fetchingText, setFetchingText] = useState<string>("Parsing")
    const [parseResponse, setParseResponse] = useState<ResponseWithMessages<PGNParseResult> | null>(null)

    const onPGNNumberChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        setPgnNumber((event.target as HTMLInputElement).value)
    }, [])

    const onParseFromNumber = useCallback(async () => {
        setFetching(true)
        setFetchingText("Parsing")
        setParseResponse(null)
        try {
            const parseResponse = await parsePGN(pgnNumber)
            setParseResponse(parseResponse)
            const result = parseResponse.result
            if (result) {
                setPgnFields({
                    priority: result.priority,
                    extendedDataPage: result.extendedDataPage,
                    dataPage: result.dataPage,
                    pduFormat: result.pduFormat,
                    pduSpecific: result.pduSpecific,
                    sourceAddress: result.sourceAddress
                })
            }
        } catch (error) {
            setParseResponse({messages: (error as ResponseErrorWithMessages).responseMessages })
        } finally {
            setFetching(false)
        }
    }, [pgnNumber])

    const onParseFromFields = useCallback(async () => {
        setFetching(true)
        setFetchingText("Building...")
        setParseResponse(null)
        try {
            const parseResponse = await buildPGN(pgnFields)
            setParseResponse(parseResponse)
            parseResponse.result && setPgnNumber(parseResponse.result.pgnString)
        } catch (error) {
            setParseResponse({messages: (error as ResponseErrorWithMessages).responseMessages})
        } finally {
            setFetching(false)
        }
    }, [pgnFields])

    const onNumberKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onParseFromNumber()
        }
    }, [onParseFromNumber])

    const onFieldInputChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement
        const attributeName = target.dataset.bindAttribute as keyof PGNFields
        const isCheckbox = target.type === 'checkbox'

        if (isCheckbox) {
            (pgnFields[attributeName] as boolean) = target.checked
        } else {
            const num = parseInt(target.value)
            if (isNaN(num)) {
                (pgnFields[attributeName] as any) = null
            } else {
                (pgnFields[attributeName] as number) = parseInt(target.value)
            }
        }
        setPgnFields({...pgnFields})
    }, [pgnFields])

    const onCopyClick = useCallback(() => {
        navigator.clipboard.writeText(JSON.stringify(parseResponse?.result))
    }, [parseResponse?.result])

    return (
        <Grid container spacing={2} sx={{width:1, height: 1}}>
            <Grid item sm="auto" container direction="column" spacing={2} >
                <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <TextField
                                value={pgnNumber}
                                autoFocus
                                size="small"
                                label="Enter the PGN as a HEX number"
                                onInput={onPGNNumberChange}
                                onKeyPress={onNumberKeyPress}
                                sx={{minWidth: '300px'}}
                            />
                            <Button
                                variant="contained"
                                onClick={onParseFromNumber}
                                fullWidth
                                sx={{display: 'block', mt: 2}}
                            >Parse PGN</Button>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item>
                    <Card variant="outlined">
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    <InputRow fields={pgnFields} title='Priority' attr={'priority'} min={0} max={3} onChange={onFieldInputChange}/>
                                    <CheckboxRow fields={pgnFields} title='Extended Data Page' attr={'extendedDataPage'}  onChange={onFieldInputChange}/>
                                    <CheckboxRow fields={pgnFields} title='Data Page' attr={'dataPage'}  onChange={onFieldInputChange}/>
                                    <InputRow fields={pgnFields} title='PDU Format' attr={'pduFormat'} min={0} max={255}  onChange={onFieldInputChange}/>
                                    <InputRow fields={pgnFields} title='PDU Specific' attr={'pduSpecific'} min={0} max={255}  onChange={onFieldInputChange}/>
                                    <InputRow fields={pgnFields} title='Source address' attr={'sourceAddress'} min={0} max={255} onChange={onFieldInputChange}/>
                                </TableBody>
                            </Table>
                            <Button
                                variant="contained"
                                onClick={onParseFromFields}
                                fullWidth
                                sx={{mt: 2}}
                            >
                                Construct PGN
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item sm="auto" sx={{flexShrink: {sm: 1}}}>
                {fetching && (
                    <Typography>{fetchingText}</Typography>
                )}
                {parseResponse?.messages && (
                    <ResponseMessages messages={parseResponse.messages} />
                )}
                {parseResponse?.result && (
                    <Card variant="outlined">
                        <CardContent>
                            <Typography sx={{mb: 2, textAlign: 'center'}}>PGN parsing results</Typography>
                            <Table size="small"> 
                                <TableBody>
                                    {Object.keys(parseResponse.result)
                                        .filter(key => (parseResponse.result as any)[key] !== null)
                                        .map(key => ( 
                                            <TableRow key={key}> 
                                                <TableCell>{key}</TableCell> 
                                                <TableCell>{(parseResponse.result as any)[key].toString()}</TableCell> 
                                            </TableRow> 
                                        ))
                                    } 
                                </TableBody>
                            </Table> 
                            <Button 
                                variant="contained"
                                fullWidth
                                color="secondary"
                                onClick={onCopyClick}
                                sx={{mt: 2}}
                            >Copy as JSON</Button> 
                        </CardContent>
                    </Card>
                )} 
            </Grid>
            <InfoBox>
                    <PGNHelp/>
            </InfoBox>
        </Grid>
    )
}