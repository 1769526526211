import  {  useCallback, useState } from "react"
import { Grid, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material"
import InfoBox from "./Common/InfoBox"
import DateTimeUtilsHelp from "./help/DateTimeUtilsHelp"


export default function DateTimeUtils() {
    const [dateString, setDateString] = useState<string>('')
    const [dateValue, setDateValue] = useState<number>(0)
    const [timeString, setTimeString] = useState<string>()
    const [timeValue, setTimeValue] = useState<number>()
    const [epochValue, setEpochValue] = useState<number>()
    const [epochString, setEpochString] = useState<string>()

    const startDate = new Date("1980-01-01T00:00:00.000Z");

    function pad(num:number, size:number) {
        let numText = num.toString();
        while (numText.length < size) numText = "0" + numText;
        return numText;
    }

    function addDays(date:Date, days:number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }        
    const onDateInputChange = useCallback((value: number) => {
        setDateValue(value)
        setDateString( addDays(startDate,value).toDateString())
    }, [])

    const onTimeInputChange = useCallback((value: number) => {
        setTimeValue(value)
        let MilliSeconds = value % 1000;
        value = Math.trunc(value/1000);
        let Seconds = value % 60;
        value = Math.trunc(value/60);
        let Minutes = value % 60;
        let Hours = Math.trunc(value/60)
        setTimeString(""+pad(Hours,2)+":"+pad(Minutes,2)+":"+pad(Seconds,2)+"."+pad(MilliSeconds,4))
    }, [])


    const onEpochSecondsInputChange = useCallback((value:number) => {
        setEpochValue(value)
        setEpochString(new Date(value*1000).toUTCString());

    },[])

    return (
        <Grid container wrap="wrap" spacing={2} height={1}>
            <Grid item>
                <h1>DateUtils to handle ISOXML Dates</h1>
                <h2>ISOXML Binary Data Dates</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Days since 01.01.1980 (ISOXML-Epoch)</TableCell>
                            <TableCell>                
                                    <TextField
                                        type="number"
                                        value={dateValue}
                                        onChange={(e) => onDateInputChange(+e.target.value)}
                                    />
                            </TableCell>
                            <TableCell>
                                <b>Date: </b> {dateString}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MilliSeconds since midnight (ISOXML-Epoch)</TableCell>
                            <TableCell>                
                                    <TextField
                                        type="number"
                                        value={timeValue}
                                        onChange={(e) => onTimeInputChange(+e.target.value)}
                                    />
                            </TableCell>
                            <TableCell>
                                <b>Time: </b> {timeString}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <h2>Others</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Seconds since epoch (1970-01-01)</TableCell>
                            <TableCell>                
                                    <TextField
                                        type="number"
                                        value={epochValue}
                                        onChange={(e) => onEpochSecondsInputChange(+e.target.value)}
                                    />
                            </TableCell>
                            <TableCell>
                                <b>Time: </b> {epochString}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <InfoBox>
                    <DateTimeUtilsHelp/>
            </InfoBox>            
        </Grid>
    )
}