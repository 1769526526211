import  { useEffect } from "react";
import Header from "./Common/Header";
import { Route, Routes } from "react-router-dom";
import Index from "./Index";
import PGN from "./PGN";
import ClientName from "./ClientName";
import DeviceDescription from "./DeviceDescription";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { startInit } from "../state/authSlice";
import { store } from "../state/store";
//import Shape2ISOXML from "./Shape2ISOXML";
import Footer from "./Common/Footer";
import ISOXMLCheck from "./ISOXMLCheck"
import ISOXMLFinisher from "./ISOXMLFinisher";
import DateTimeUtils from "./DateTimeUtils";
import Shape2ISOXML from "./Shape2ISOXML";

const theme = createTheme({
  palette: {
    primary: {
      main: "#BFD878",
    },
    secondary: {
      main: "#909190",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          height: "100%",
        },
        html: {
          height: "100%",
        },
        "#root": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    startInit(store.dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Box sx={{ m: 2, flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/date-time" element={<DateTimeUtils />}></Route>
          <Route path="/pgn" element={<PGN />}></Route>
          <Route path="/clientName" element={<ClientName />}></Route>
          <Route path="/dvc" element={<DeviceDescription />}></Route>
          <Route path="/isoxml-check" element = {<ISOXMLCheck />}> </Route>
          <Route path="/isoxml-finisher" element = { <ISOXMLFinisher />}></Route>
          <Route path="/shape2isoxml" element = {<Shape2ISOXML />}></Route>
        </Routes>
      </Box>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
