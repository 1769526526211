import { AccountCircle, MoreVert } from "@mui/icons-material";
import { Menu, MenuItem, Box } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import AuthService from "../auth-config/auth-service";
import { isInitializedSelector, userProfileSelector } from "../state/authSlice";

export default function User() {
  // @ts-ignore AuthService.getInstance() is null
  const { isLoggedIn, login, logout, register } = AuthService.getInstance();

  const isInitialized = useSelector(isInitializedSelector);
  const profile = useSelector(userProfileSelector);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const renderProfileControls = useCallback(
    () =>
      !isInitialized || !isLoggedIn ? (
        <Box>
        <Button variant="contained" color="secondary" onClick={login} sx={{mx:2}}>
          Login
        </Button> 
        or 
        <Button variant="contained" color="secondary" onClick={register} sx={{mx:2}}>
          Register
        </Button>         
        </Box>
      ) : (
        <>
          {/* <AccountCircle color="secondary"/> */}
          <Button
            onClick={handleMenuClick}
            color="secondary"
            startIcon={<AccountCircle />}
            endIcon={<MoreVert />}
          >
            {profile.username}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </>
      ),
    [isInitialized, isLoggedIn, menuAnchorEl, login, logout, profile, register]
  );

  return renderProfileControls();
}
