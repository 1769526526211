import { Box, Table, TableBody, TableRow } from "@mui/material";
import { StyledTableCell } from "../utils";


export default function FinisherHelp() {
    return (
        <Box>
            <h1> What is the ISOXML Finisher?</h1>
            The ISOXML Finisher is a tool to simulate work on an initial taskset. Its purpose is to test the full cycle of exporting and importing from and to an FMIS,
            without the requirement for real field work. 

            <h1>What can this tool do</h1> 
            This tool imports an initial ISOXML and simulates work on the fields within the TaskSet. e.g. the Workstate becomes active if the machine is in the field.

            <h2>How it works</h2>
            
            <Table>
                <TableBody>
                    <TableRow>
                        <StyledTableCell><b>1</b></StyledTableCell>
                        <StyledTableCell>Upload an initial TaskSet</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell><b>2</b></StyledTableCell>
                        <StyledTableCell>Select the StartDate and Time for the first field</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell><b>3</b></StyledTableCell>
                        <StyledTableCell>Select the Tractor and implement that shall be used on the field</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell><b>4</b></StyledTableCell>
                        <StyledTableCell>Select WorkingWidth and WorkingSpeed. The Field "Major" describes if those data shall be overwritten by information from the DeviceDescription (e.g. an existing DefaultWorkingWidth)</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell><b>5</b></StyledTableCell>
                        <StyledTableCell>Click on "Finish TaskSet"</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </Box>
    );
}
