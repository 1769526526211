import React from "react"
import { Box, Card, CardContent } from "@mui/material"

export const ELEMENT_TYPE_COLORS = [
    {type: 'device', color: '#8dd3c7'},
    {type: 'function', color: '#ffffb3'},
    {type: 'bin', color: '#bebada'},
    {type: 'section', color: '#fb8072'},
    {type: 'unit', color: '#80b1d3'},
    {type: 'connector', color: '#fdb462'},
    {type: 'navigation', color: '#b3de69'}
]
export const COLOR_BY_ELEMENT_TYPE = ELEMENT_TYPE_COLORS.reduce((res, item) => {
    res[item.type] = item.color
    return res
}, {} as {[type: string]: string})

interface GraphLegendProps {
    types: string[]
}

export default function GraphLegend({types}: GraphLegendProps) {
    return (
        <Card sx={{position: 'absolute', top: 8, right: 0, zIndex: 1}} variant="outlined">
            <CardContent>
                {ELEMENT_TYPE_COLORS
                    .filter(item => types.includes(item.type))
                    .map(item => (
                        <div key={item.type}>
                            <Box sx={{
                                display: 'inline-block',
                                width: 20,
                                height: 20,
                                border: '1px solid gray',
                                verticalAlign: 'middle',
                                mr: 1
                            }} style={{backgroundColor: item.color}}/>
                            <Box
                                component="span"
                                sx={{verticalAlign: 'middle', textTransform: 'capitalize'}}
                            >
                                {item.type}
                            </Box>
                        </div>
                    ))
                }
            </CardContent>
        </Card>
    )
}