import ky, { BeforeRequestHook } from 'ky';
import {
  DeviceAnalysisResult,
  PGNFields,
  PGNParseResult,
  ResponseMessage,
  ResponseWithMessages,
  Shape2IsoXmlFields,
  ClientNameFields,
  ClientNameParseResult,
  ISOXMLCheckFields,
  ISOXMLFinisherFields,
} from './dto';
import { buildFormData, downloadFileFromResponse } from './utils';
import { AuthLocalStorageKeys } from '../auth-config/auth-service';

const authHook: BeforeRequestHook = (request) => {
  const token = localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN)
  const header = request.headers.get("Authorization")
  if (!header?.startsWith("Basic") && token) request.headers.append("Authorization", `Bearer ${token}`)
};

const server = ky.create({
  prefixUrl: process.env.REACT_APP_DEV4AG_TOOLS_URL,
  throwHttpErrors: false,
  hooks: { beforeRequest: [authHook] }
});

export class ResponseErrorWithMessages extends Error {
  responseMessages: ResponseMessage[];
  constructor(message: string, responseMessages: ResponseMessage[]) {
    super(message);
    this.responseMessages = responseMessages;
  }
}

async function processServerResponse<OutputType>(
  response: Response
): Promise<ResponseWithMessages<OutputType>> {
  let json: ResponseWithMessages<OutputType>;
  try {
    json = (await response.json()) as ResponseWithMessages<OutputType>;
  } catch {
    const msg: ResponseMessage = {
      type: "Error",
      code: "response-parse-error",
      title: "Server Error",
      details: {
        responseCode: response.status,
      },
      description: "Can't parse server-side reponse"
    };
    throw new ResponseErrorWithMessages(
      `Can't parse server-side reponse (status code: ${response.status})`,
      [msg]
    );
  }
  if (response.ok) {
    return json;
  } else if (response.status === 400) {
    throw new ResponseErrorWithMessages("Validation error", json.messages);
  } else {
    throw new ResponseErrorWithMessages(
      `Server-side error (status code: ${response.status})`,
      [
        {
          type: "Error",
          code: "server-error",
          title: "Server-side error",
          details: {
            responseCode: response.status,
          },
          description: "Server-Side Error"
        },
      ]
    );
  }
}

async function baseRequestFunction<OutputType>(
  url: string,
  params: any
): Promise<ResponseWithMessages<OutputType>> {
  let response: Response;
  try {
    response = await server.get(url, {
      searchParams: params,
    });
  } catch (err: any) {
    const msg = err instanceof Error ? err.message : (err as string);
    throw new ResponseErrorWithMessages(err, [
      {
        type: "Error",
        code: "server-unavailable",
        title: "Server unavailable",
        description : msg
      },
    ]);
  }
  return processServerResponse(response);
}

export function parsePGN(pgn: string) {
  return baseRequestFunction<PGNParseResult>("pgn/parse", { pgn });
}

export async function buildPGN(pgnFields: PGNFields) {
  return baseRequestFunction<PGNParseResult>("pgn/build", pgnFields);
}

export function parseClientName(clientNameString: string) {
  return baseRequestFunction<ClientNameParseResult>("clientName/parse", { clientNameString });
}

export async function buildClientName(clientNameFields: ClientNameFields) {
  return baseRequestFunction<ClientNameParseResult>("clientName/build", clientNameFields);
}


export async function analyzeDevice(
  xmlDeviceDescription: string,
  includeElements: boolean = false,
  includeDevice: boolean = false,
  includeHierarchy: boolean = true
) {
  const formData = new FormData();
  formData.append("deviceXML", xmlDeviceDescription);

  const response = await server.post(
    `device/analyze?includeElements=${includeElements}&includeDevice=${includeDevice}&includeHierarchy=${includeHierarchy}`,
    {
      body: formData,
    }
  );

  return processServerResponse<DeviceAnalysisResult>(response);
}

export async function convertDeviceToDDOP(
  xmlDeviceDescription:string,
  version:number
){
  const formData = new FormData();
  formData.append("deviceXML", xmlDeviceDescription);
  const response = await server.post(
    `device/convertToBin?version=`+version,{
      body:formData
    }
  )
  if (!response.ok) {
    if(response.body!==undefined){
      return await response.json()
    } else {
      return {messages:[
        { 
          code: "100",
          title: 'No response received',
          type:'Error',
          description:'No response received'
        }
      ]}
    }
  }

  await downloadFileFromResponse(response)

  return;
}


export async function shareData(data: string, comment?: string) {
  const formData = new FormData();
  formData.append("data", data);
  if (comment) {
    formData.append("comment", comment);
  }

  const response = await server.post("sharedData", {
    body: formData,
  });

  return processServerResponse<never>(response);
}

export async function transformShapeToIsoXml(payload: Shape2IsoXmlFields):Promise<any> {
  try {
    const formData = new FormData()
    buildFormData(formData, payload)
    const nextData = new FormData();

    //TODO: Due to an issue with Swatchbuckle in C# ASP.NET, we need to add a ".config" as prefix to all API call parameters.
    //      This should be fixed in the future.
    for( const [key, value] of formData.entries()){
      let name = "config."+key;
      nextData.append(name,value);
    }
    const response = await server.post("shp2isoxml", {
      body: nextData,
      timeout: 100000
    });

    if (!response.ok) {
      if(response.body!==undefined){
        return await response.json()
      } else {
        return {messages:[
          { 
            code: "100",
            title: 'No response received',
            type:'Error',
            description:'No response received'
          }
        ]}
      }
    }

    await downloadFileFromResponse(response)

    return;
  } catch (_) {
    return { messages: ['Unexpected error happened, please try again!'] }//TODO Wrong format
  }
}


export async function checkISOXMLTaskSet(payload: ISOXMLCheckFields): Promise<ResponseWithMessages<void>> {
  try {
    const formData = new FormData()
    buildFormData(formData, payload)
    const response = await server.post("isoxml/check", {
      body: formData,
      timeout: 100000
    });
    if( response){
      return await response.json()      
    } else {
      return { messages: [{ 
        code: "100",
        title: 'No response received',
        type:'Error',
        description:'No response received'
      }] };
    }
  } catch (_) {
    return { messages: [{ 
      code: "100",
      title: 'Unexpected Error',
      type:'Error',
      description:'Unexpected error happened, please try again!'
    }]  }
  }
}

export async function convertResultISOXMLTaskSet(payload: ISOXMLCheckFields):Promise<any> {
  try {
    const formData = new FormData()
    buildFormData(formData, payload)
    const response = await server.post("isoxml/to-result-zip", {
      body: formData,
    });

    if (!response.ok) {
      if( Object.keys(response.json()).length>0){
        return await response.json()
      } else {
        return {
          messages:[
            { 
              code: "100",
              title: 'No response received',
              type:'Error',
              description:'No response received'
            }
          ]
        }
      }
    }

    await downloadFileFromResponse(response)

    if(Object.keys(response.json()).length > 0 ){
      return response.json();
    } else {
      return {
        messages:[
          { 
            code: "100",
            title: 'No response received',
            type:'Error',
            description:'No response received'
          }
        ]
      }
    }
  } catch (_) {
    return { message: 'Unexpected error happened, please try again!' }
  }
}


export async function finishISOXMLTaskSet(payload: ISOXMLFinisherFields){
  try {
    const formData = new FormData()
    buildFormData(formData,payload)
    const response = await server.post("isoxml/finish",{
      body: formData
    })
    if( response.ok){
      await downloadFileFromResponse(response)
    }
    return;
  } catch (_) {
    return { message: 'Unexpected error happened, please try again!' }
  }
}