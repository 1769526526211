import { Box, FormControl, NativeSelect, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";

export class DeviceSelectionFieldProps{
    public Title:string;
    public ChangeCallback:any;
    public SelectionList:string[][]
    public disabled:any

    constructor(_title:string, _selectionList:string[][], _disabled:any ){
        this.Title = _title
        this.SelectionList = _selectionList
        this.disabled = _disabled
    }

}



export function DeviceSelectionField(params: DeviceSelectionFieldProps){
    const [dvcText,setDVCText] = useState<string>(params.SelectionList[0][1]);
    const [enableText,setEnableText] = useState<boolean>();
    const onTextChanged = params.ChangeCallback


    const onChange=  useCallback((text: string) => {
        onTextChanged(text)        
    }, [onTextChanged]);


    const onSelect = (event:any) =>{
        console.log("SelectionEvent: " + event.target.value)
        params.SelectionList.forEach(kvPair => {
            if( kvPair[0] === event.target.value){
                setDVCText(kvPair[1])                
                setEnableText(kvPair[1] === '')
                onChange(kvPair[1])
                return;
            }
        });
    }

    const onTextFieldChange = (event:any) => {
        setDVCText(event.target.value);
        onChange(event);
      };    



    return (
        <Box 
            sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'top',
            gap: 1, // Adjust the gap between the label and the picker
            }}>
            <Typography>{params.Title}</Typography>
            <FormControl fullWidth>
                <NativeSelect
                    autoFocus
                    defaultValue={30}
                    inputProps={{
                    name: 'Device',
                    id: 'uncontrolled-native',
                    }}
                    onChange={ event => onSelect(event)}
                    disabled={params.disabled}
                >
                    {params.SelectionList.map((entry)=>
                        <option value={entry[0]} key={entry[0]}>{entry[0]}</option>
                    )}
                </NativeSelect>
            </FormControl>
            <TextField
                label=""
                multiline
                fullWidth
                rows={6}
                value={dvcText}
                onChange={onTextFieldChange}
                disabled={!enableText}
                sx={{ height:1 }}
                InputProps={{ sx: { height: "1" } }}
                inputProps={{ style: { height: "1" } }}  
                />
        </Box>
    )
}