import React, { ChangeEvent, useCallback, useState } from 'react';
import { Button, Tooltip } from '@mui/material';

interface FilePickerProps {
    onChange: (file?: File) => void
}

export function FilePicker({ onChange: onFileSelected }: FilePickerProps) {
    const [file, setFile] = useState<File>()

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const fileToSelect = event.target.files ? event.target.files[0] : undefined
        setFile(fileToSelect)
        onFileSelected(fileToSelect)
    }, [onFileSelected]);

    return <>
        <Tooltip hidden={!file} title={file?.name}>
            <Button
                color={file ? 'primary' : 'secondary'}
                variant='outlined'
                component='label'
                fullWidth
                style={{
                    maxWidth: '150px',
                    display: 'block',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}
            >
                {file ? file.name : 'Upload'}
                <input hidden accept=".zip" type="file" onChange={onChange} />
            </Button>
        </Tooltip>
    </>
}