import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeviceDTO, DeviceElement } from "../../orm/dto";
import DeviceElementView from "./DeviceElement";

interface DeviceElementsProps {
    deviceElements: DeviceElement[]
    device: DeviceDTO
}

interface DeviceAccordionParams {
    title: string
    objectId: number
    children: any
}

function DeviceAccordion({title, objectId, children}: DeviceAccordionParams) {
    return (
        <Accordion key={objectId} elevation={0} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{bgcolor: 'primary'}}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{border: '1px solid rgba(0, 0, 0, 0.125)'}}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default function DeviceElements({deviceElements, device}: DeviceElementsProps) {
    return (<>
        {deviceElements.map(element => (
            <DeviceAccordion key={element.objectId} objectId={element.objectId} title={`Element ${element.designator || element.objectId}`}>
                <DeviceElementView element={element} />
            </DeviceAccordion>
        ))}
    </>)
}