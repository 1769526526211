import React, { useCallback, useState } from "react";
import { Button, Card, CardContent, Grid, Table, TableBody, TableRow, styled } from "@mui/material";

import { ISOXMLCheckResult, ResponseMessage } from "../orm/dto";
import LoginRequired from "./LoginRequired";
import InfoBox from "./Common/InfoBox";
import ISOXMLCheckHelp from "./help/ISOXMLCheckHelp";
import { StyledTableCell } from "./utils";
import { FilePicker } from "./Common/FilePicker";
import { checkISOXMLTaskSet, convertResultISOXMLTaskSet } from "../orm/dev4ag";

const ColoredTableCell = styled(StyledTableCell)<{ cellcolor: string }>`
    background-color: ${props => props.cellcolor};
`;




const ResponseMessageRow: React.FC<{ message: ResponseMessage }> = ({ message }) => {
    let color;
    switch (message.type) {
        case 'Error':
            color = 'red';
            break;
        case 'Warning':
            color = 'orange';
            break;
        case 'Info':
            color = 'green';
            break;
        default:
            color = 'white';
    }

    return (
        <TableRow>
            <ColoredTableCell cellcolor={color}>{message.type}</ColoredTableCell>
            <StyledTableCell>{message.title}</StyledTableCell>
            <StyledTableCell>{message.description}</StyledTableCell>
        </TableRow>
    );
};



export default function ISOXMLCheck() {

    const [isoxmlFile, setISOXMLFile] = useState<File>()
    const [analysisResponse, setAnalysisResponse] = useState<ISOXMLCheckResult | null>(null)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isActive, setIsActive] = useState<boolean>();

    const onCheck = useCallback(async () => {
        setIsActive(true)
        setAnalysisResponse(null)
        const result = await checkISOXMLTaskSet({
            ISOXMLFile: isoxmlFile,
            Config: {
                CheckProprietaryElements : false
            }
        })
        if (result &&  result["messages"]) {
            setAnalysisResponse(new ISOXMLCheckResult(result.messages))
        }
        setIsActive(false)
    }, [isoxmlFile, analysisResponse])



    const onConvert = useCallback(async () => {
        setIsActive(true);
        setAnalysisResponse(null)

        const error = await convertResultISOXMLTaskSet({
            ISOXMLFile: isoxmlFile,            
            Config: {
                CheckProprietaryElements: true
                }
            }
        )
        if (error && error.messages && error.messages.length>0) {
            setErrorMessage(error.messages[0].title)
        }
        setIsActive(false);
    }, [isoxmlFile, analysisResponse])


    function ResultDisplay(){
        if( !analysisResponse){
            return <Table><TableBody><TableRow><StyledTableCell>Select a File and click on Analyse</StyledTableCell></TableRow></TableBody></Table>
        }
        if( analysisResponse.isValid){
            return (
                <Table><TableBody><TableRow><StyledTableCell>This TaskSet is Valid!</StyledTableCell></TableRow></TableBody></Table>
            )
        } else {
            return (
                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell>Infos: {analysisResponse.infoCount}</StyledTableCell>
                                <StyledTableCell>Warnings: {analysisResponse.warningCount}</StyledTableCell>
                                <StyledTableCell>Error: {analysisResponse.errorCount}</StyledTableCell>
                            </TableRow> 
                            <TableRow>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Title</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                            </TableRow>
                            {analysisResponse?.messages.map((message, index) => (<ResponseMessageRow key={index} message={message} />))}
                        </TableBody>                            
                    </Table>
            )
        }
    }


    return (
        <LoginRequired>
            <Grid container wrap="nowrap" spacing={2} height={1}>
                <Grid item width='530px' sm='auto' container direction="column" spacing={2}>
                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>ZipFolder with ISOXML TaskSet</StyledTableCell>
                                            <StyledTableCell>
                                                <FilePicker onChange={(file) => setISOXMLFile(file)} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button onClick={onCheck} disabled={isActive}>Check</Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button onClick={onConvert} disabled={isActive}>Check And Download</Button>
                                            </StyledTableCell>
                                        </TableRow>
                                        { isActive &&
                                            <TableRow>
                                                <StyledTableCell>
                                                    Calculating....
                                                </StyledTableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                {ResultDisplay()}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <InfoBox>
                    <ISOXMLCheckHelp/>
                </InfoBox>            
            </Grid>
       </LoginRequired>
    )
}