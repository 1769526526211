import { Box } from "@mui/material";


export default function DeviceDescriptionHelp() {
    return (
        <Box>
            <h1>ISOXML DeviceDescription analysis</h1>
            The analyser checks and visualizes ISOXML DeviceDescriptions. Copy any 	&lt;DVC.... to 	&lt;/DVC&gt; from your TASKDATA.XML to see
            <ul>
                <li> A list of issues</li>
                <li> The Device Structure </li>
                <li> Details on each DeviceElement</li>
                <li> Technical information from the device header </li>
            </ul>
            <h2>How it works</h2>
            <video controls preload="auto" poster="vid/deviceDesrciption_poster.png" width="100%" src="vid/deviceDescription.webm">
                <img src="vid/deviceDesrciption_poster.png" alt="How it works" width="100%" height="100%"/>
            </video>
        </Box>
    );
}
