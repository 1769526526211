import { Checkbox, FormControlLabel, TableCell, TableRow, TextField } from "@mui/material";
import { ValueRange } from "../../orm/dto";
import { useEffect, useState } from "react";

export class DataRangeFieldProps{
    public Title: string
    public DataRange:ValueRange
    public onChange:any
    public Unit:string
    public disabled:any
    constructor(_title:string,_dataRange: ValueRange, _updaterCallback: any, _unit:string, _disabled: any){
        this.Title = _title
        this.DataRange =_dataRange
        this.Unit = _unit
        this.disabled = _disabled
    }
}

export function DataRangeField(params: DataRangeFieldProps) {
    const [min, setMin] = useState(params.DataRange.Min)
    const [max, setMax] = useState(params.DataRange.Max)
    const [defaultValue, setDefaultValue] = useState(params.DataRange.Default)
    const [changePerSecond, setChangePerSecond] = useState(params.DataRange.ChangePerSecond)
    const [major, setMajor] = useState(params.DataRange.Major)
    const [validRange, setValidRange] = useState(true)

    useEffect(() => {
        var minOk = params.DataRange.updateMin(min)
        var maxOk = params.DataRange.updateMax(max)
        var defaultOk = params.DataRange.updateDefault(defaultValue)
        params.DataRange.ChangePerSecond = changePerSecond
        params.DataRange.Major = major

        setValidRange(minOk && maxOk && defaultOk);
        params.onChange(params.DataRange)
    }, [min, max, defaultValue, changePerSecond, major]);

    const GetValue=(_input:number):number=>{
        return params.DataRange.GetValue(_input)
    }

    const ConvertValue=(_input:number):number=>{
        return params.DataRange.ConvertValue(_input)
    }

    return (
        <TableRow>
            <TableCell>{params.Title} ({params.Unit})</TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={GetValue(min)}
                    onChange={(e) => setMin(ConvertValue(+e.target.value))}
                    error={!validRange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={GetValue(max)}
                    onChange={(e) => setMax(ConvertValue(+e.target.value))}
                    error={!validRange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={GetValue(defaultValue)}
                    onChange={(e) => setDefaultValue(ConvertValue(+e.target.value))}
                    error={!validRange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    value={GetValue(changePerSecond)}
                    onChange={(e) => setChangePerSecond(ConvertValue(+e.target.value))}
                    error={!validRange}
                />
            </TableCell>
            <TableCell>
               <FormControlLabel
                    control={<Checkbox checked={major} onChange={(e) => setMajor(e.target.checked)} />}
                    label="Major"
                />
            </TableCell>
        </TableRow>
    );
}