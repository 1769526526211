import { useCallback, useState } from "react";
import { Box, Button, Card, CardContent, Grid, Table, TableBody, TableRow, Typography, styled } from "@mui/material";
import {  ValueRange } from "../../orm/dto";
import LoginRequired from "../LoginRequired";
import InfoBox from "../Common/InfoBox";
import { StyledTableCell } from "../utils";
import { FilePicker } from "../Common/FilePicker";
import {  finishISOXMLTaskSet } from "../../orm/dev4ag";
import ISOXMLFinisherHelp from "../help/ISOXMLFinisherHelp";
import { DeviceSelectionField } from "./DeviceSelectionField";
import { DataRangeField } from "./DataRangeField";
import { DevicesExamples } from "./DeviceExamples";
import { BasicDateTimePicker } from "../Common/BaseDatePicker";

const ColoredTableCell = styled(StyledTableCell)<{ cellcolor: string }>`
    background-color: ${props => props.cellcolor};
`;


const DataRangeHeader = ()=>{
    return (
        <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Min</StyledTableCell>
            <StyledTableCell>Max</StyledTableCell>
            <StyledTableCell>Default</StyledTableCell>
            <StyledTableCell>Change per Second</StyledTableCell>
            <StyledTableCell>Major</StyledTableCell>
        </TableRow>
    )
}



export default function ISOXMLFinisher() {

    const [isoxmlFile, setISOXMLFile] = useState<File>()
    const [startTime, setStartTime] = useState<Date>(new Date(2023,6,30,10,11,12))
    const [tractorDescriptions, setTractorDescriptions] = useState<string>("")
    const [implementDescriptions, setImplementDescriptions] = useState<string>("")
    const [setpointRange, setSetpointRange] = useState<ValueRange>(new ValueRange(2000,4000,3000,true,500, 1000,0))
    const [workingSpeedRange, setWorkingSpeedRange] = useState<ValueRange>(new ValueRange(2000,4000,3000,true,500,1000/3.6, 0))
    const [workingWidthRange, setWorkingWidthRange] = useState<ValueRange>( new ValueRange(20000,40000,30000,true,5000, 1000,0))

    let deviceDescriptions:string="";


    const [errorMessage, setErrorMessage] = useState<string>()
    const [isActive, setIsActive] = useState<boolean>()


    const onTractorChange = (text: string) =>{
        setTractorDescriptions(text);
        console.log("ImplementsText: "+implementDescriptions)
        console.log("TractorText: "+tractorDescriptions)
    }


    const onImplementChange = (text: string) =>{
        setImplementDescriptions(text)
        console.log("ImplementsText: "+implementDescriptions)
        console.log("TractorText: "+tractorDescriptions)
    }

    const onFinish = useCallback(async () => {
        setIsActive(true);

        if(startTime == undefined){
            setErrorMessage("No date selected");
            setIsActive(false);
            return;

        }

        if( !workingSpeedRange.check(4,60)){
            setErrorMessage("SpeedRange is invalid, should be 4-60km/h")
            setIsActive(false);
            return;
        }

        if( !workingWidthRange.check(4,60)){
            setErrorMessage("WorkingWidthRange is invalid, should be 4-60 m")
            setIsActive(false);
            return;
        }

        const getCombinedDeviceDescription = ():string=>{
            var text = '<ISO11783_TaskData VersionMajor="4" VersionMinor="1" ManagementSoftwareManufacturer="" ManagementSoftwareVersion="" TaskControllerManufacturer="KRONE Smart Connect" TaskControllerVersion="1.104.49.0" DataTransferOrigin="2">'
            text += tractorDescriptions +"\n"
            text += implementDescriptions + "\n"
            text += '</ISO11783_TaskData>'
            console.log("Descriptions combined: " + text)
            return text;
    
        }
    

        const error = await finishISOXMLTaskSet({
            ISOXMLFile: isoxmlFile,            
            Config: {
                Setpoint : setpointRange,
                StartTime: startTime.toISOString().split('.')[0]+"Z",
                WorkingSpeed: workingSpeedRange,
                WorkingWidth: workingWidthRange
                },
                DeviceDescriptions: getCombinedDeviceDescription()
            }
        )
        if (error && error.message) {
            setErrorMessage(error.message)
        }
        setIsActive(false);
    }, [isoxmlFile, deviceDescriptions, tractorDescriptions, implementDescriptions, workingSpeedRange, setpointRange, workingWidthRange])



    return (
        <LoginRequired>
            <Grid container wrap="nowrap" spacing={2} height={1}>
                <Grid item width='530px' sm='auto' container direction="column" spacing={2}>
                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2, // Adjust the gap between items (using the theme's spacing scale)
                                }}
                                >
                                <Box
                                    sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 1, // Adjust the gap between the label and the picker
                                    }}
                                >                                
                                    <Typography>ZipFolder with initial ISOXML TaskSet</Typography>                                                
                                    <FilePicker onChange={(file) => setISOXMLFile(file)} />
                                </Box>
                                <Box
                                        sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'right',
                                        gap: 1, // Adjust the gap between the label and the picker
                                        }}
                                >  
                                            <Typography>Select StartDate</Typography>
                                            <BasicDateTimePicker disabled={isActive}  onDateChanged={setStartTime} date={startTime}/>
                                </Box>
                                        <DeviceSelectionField Title="Tractor" disabled={isActive} ChangeCallback={onTractorChange} SelectionList={DevicesExamples.GetTractorList()}></DeviceSelectionField>
                                        <DeviceSelectionField Title="Implements" disabled={isActive} ChangeCallback={onImplementChange} SelectionList={DevicesExamples.GetImplementList()}></DeviceSelectionField>
                                        <Table size="small">
                                            <TableBody>
                                                <DataRangeHeader/>
                                                <DataRangeField Title="Working Speed"  disabled={isActive} DataRange={ workingSpeedRange }  onChange={setWorkingSpeedRange} Unit="km/h"/>
                                                <DataRangeField Title="Working Width" disabled={isActive} DataRange={ workingWidthRange }  onChange={setWorkingWidthRange} Unit="m"/>
                                                <DataRangeField Title="Setpoint" disabled={isActive} DataRange={ setpointRange }  onChange={setSetpointRange}  Unit="t"/>
                                                { isActive &&
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            Calculating....
                                                        </StyledTableCell>
                                                    </TableRow>
                                                }
                                                { errorMessage!==undefined &&
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <b>Error during processing: {errorMessage}</b>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <Button   variant="contained" disabled={isActive} onClick={onFinish}>Finish TaskSet</Button>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                           </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <InfoBox>
                    <ISOXMLFinisherHelp/>
                </InfoBox>            
            </Grid>
       </LoginRequired>
    )
}