import Keycloak, { KeycloakLoginOptions, KeycloakRegisterOptions } from "keycloak-js";

export enum AuthLocalStorageKeys {
  AUTH_TOKEN = "auth_token",
  REFRESH_TOKEN = "refresh_token",
}

export type AuthConfig = {
  url: string;
  realm: string;
  clientId: string;
};

class AuthService {
  static instance: AuthService;

  static getInstance() {
    if (AuthService.instance) {
      return AuthService.instance;
    } else {
      console.error(
        "AuthService instance doesn't exist. Use new AuthService(config) instead"
      );
      return null;
    }
  }

  public readonly keycloak: Keycloak;
  private initialized = false;

  constructor(config: AuthConfig) {
    if (AuthService.instance) {
      throw new Error(
        "AuthService instance already existed. Use AuthService.getInstance() instead"
      );
    }
    this.keycloak = new Keycloak({
      url: config.url,
      realm: config.realm,
      clientId: config.clientId,
    });

    AuthService.instance = this;
  }

  initialize = async (options: any) => {
    const token =
      localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN) ?? undefined;
    const refreshToken =
      localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) ?? undefined;

    await this.keycloak.init({
      token,
      refreshToken,
      checkLoginIframe: false,
      ...options,
    });

    localStorage.setItem(
      AuthLocalStorageKeys.AUTH_TOKEN,
      this.keycloak.token ?? ""
    );
    localStorage.setItem(
      AuthLocalStorageKeys.REFRESH_TOKEN,
      this.keycloak.refreshToken ?? ""
    );

    this.initialized = true;

    return true;
  };

  get isLoggedIn() {
    return this.keycloak.authenticated;
  }

  get profile() {
    return this.keycloak.loadUserProfile();
  }

  login = () => this.keycloak.login();

  register= () => {
    var options:KeycloakRegisterOptions = {
        redirectUri : process.env.REACT_APP_DEV4AG_TOOLS_UI_URL
    }
    this.keycloak.register(options);
  };

  logout = () => {
    if (this.initialized) {
      var options:KeycloakLoginOptions = {
        redirectUri : process.env.REACT_APP_DEV4AG_TOOLS_UI_URL
      }
      this.keycloak.logout(options);
      localStorage.setItem(
        AuthLocalStorageKeys.AUTH_TOKEN,
        ""
      );
      localStorage.setItem(
        AuthLocalStorageKeys.REFRESH_TOKEN,
        ""
      );      
    }
  };
}
export default AuthService;
