import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ClientNameParseResult, DeviceDTO, LocalizationParseResult } from "../../orm/dto";

const DeviceInfoTableCell = styled(TableCell)({
    borderBottom: 'none',
    textAlign : 'left',
    alignContent: 'left',
    marginLeft : '1em',
})

const DeviceInfoRow = ({title, value, showEmpty}: any) => 
    value || showEmpty ? (
        <TableRow>
            <DeviceInfoTableCell>{title}</DeviceInfoTableCell>
            <DeviceInfoTableCell>{value}</DeviceInfoTableCell>
        </TableRow>
    ) : null

interface DeviceDetailsProps {
    device: DeviceDTO
}

export default function DeviceDetails({device}: DeviceDetailsProps) {
    let label: LocalizationParseResult = device.localizationLabelParsed;
    let clientName: ClientNameParseResult | undefined = device.clientNAMEParsed;
    return (
        <Table sx={{width: 'initial'}} size="small">
            <TableBody>
                <DeviceInfoRow title="Designator:" value={device.designator} />
                <DeviceInfoRow title="Serial Number:" value={device.serialNumber} />
                <DeviceInfoRow title="Structure label:" value={device.structureLabel} showEmpty />
                <DeviceInfoRow title="ClientNAME:" value={device.clientNAME} showEmpty />
                {clientName && (
                    <TableRow>
                        <DeviceInfoTableCell colSpan={2} sx={{pt: 0, pl: 4}}>
                            <Table size="small"> 
                                <TableBody> 
                                    <DeviceInfoRow title="deviceClass" value={clientName.deviceClassName} showEmpty/>
                                    <DeviceInfoRow title="Manufacturer" value={clientName.manufacturer!== undefined ? clientName.manufacturer.manufacturer : "unknown"}  showEmpty/>
                                    <DeviceInfoRow title="SerialNumber" value={clientName.serialNo} showEmpty />
                                    <DeviceInfoRow title="IndustryGroup" value={clientName.industryGroup}  showEmpty/>
                                    <DeviceInfoRow title="Function" value={clientName.function}  showEmpty/>
                                    <DeviceInfoRow title="selfConfigurable" value={clientName.selfConfigurable} showEmpty/>
                                    <DeviceInfoRow title="deviceClassInstance" value={clientName.deviceClassInstance}  showEmpty/>
                                    <DeviceInfoRow title="ecuInstance" value={clientName.ecuInstance}  showEmpty/>
                                </TableBody>
                            </Table> 
                        </DeviceInfoTableCell>
                    </TableRow>
                )}
                <DeviceInfoRow title="Localization label:" value={device.localizationLabel} showEmpty />
                {label && (
                    <TableRow>
                    <DeviceInfoTableCell colSpan={2} sx={{pt: 0, pl: 4}}>
                        <Table size="small"> 
                                <TableBody> 
                                <DeviceInfoRow title="Language:" value={label.languageCode.language} />
                                <DeviceInfoRow title="Seperator:" value={label.decimalFormat} />
                                <DeviceInfoRow title="Date Format:" value={label.dateFormat} />
                                <DeviceInfoRow title="Time Format:" value={label.timeFormat} />
                                <DeviceInfoRow title="Unit Distance:" value={label.unitDistance} />
                                <DeviceInfoRow title="Unit Mass:" value={label.unitMass} />
                                <DeviceInfoRow title="Unit Area:" value={label.unitArea} />
                                <DeviceInfoRow title="Unit Volume:" value={label.unitVolume} />
                                <DeviceInfoRow title="Unit Temperature:" value={label.unitTemperature} />
                                <DeviceInfoRow title="Unit Force:" value={label.unitForce} />
                                <DeviceInfoRow title="Unit Pressure:" value={label.unitPressure} />
                                <DeviceInfoRow title="Unit Others:" value={label.unitGeneral} />
                            </TableBody>
                        </Table> 
                    </DeviceInfoTableCell>
                </TableRow>

                )}
            </TableBody>
        </Table>
    )
}
