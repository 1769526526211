import { Box } from "@mui/material";


export default function ISOXMLCheckHelp() {
    return (
        <Box>
            <h1> ISOXML Checker?</h1>
            Upload an ISOXML File to check for errors and warnings.

            <h2>What are warnings</h2> 
            Warnings report issues with the ISOXML that - for some terminals - can cause issues, even though it's not "officially" wrong or right.
            <h2>What are errors</h2>
            Errors report issues that will cause issues in every or nearly every terminal. 

            <h1> ISOXML Converter</h1>
            The converter is a useful tool to convert all Data of an ISOXML TaskSet to a readable format. It returns a zipfile with TimeLogs and Grids as CSV, The ISOXML in JSON format and the messages in JSON as well

            <h2>What is it good for?</h2>
            This can be super helpful if you have no compiler on hand and want to make an ISOXML File readable byte by byte. It's not that useful for a common overview (better use the ISOXML-Viewer for that) but to check
            specific details and get the RawData from TimeLogs and Grids.


            <h2>What is it <b>NOT</b> good for?</h2>
            The data should not be a base for further analysis algorithms; there's is more useful functions within our Libraries to really get all information.
        </Box>
    );
}
