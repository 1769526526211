import React from 'react';
import { Grid, Typography, Link } from '@mui/material'

var style = {
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '20px',
    position: 'fixed',
    left: '0',
    bottom: '0px',
    height: '80px',
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFFFFF'
}as React.CSSProperties

var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
    backgroundColor: '#FFFFFF'    
  }

var footerStyle = {
  backgroundColor:"#FFFFFF",
  width:'100%',
  height:'100%',
  display:'fixed'
}

interface FooterBoxProps {
    children: any;
  }

export function FooterContainer({children}:FooterBoxProps){
    return (
        <div style={footerStyle}>
            <div style={phantom} />
            <div style={style}>
                { children }
            </div>
        </div>
    )

}


export default function Footer() {

  const content = {
    'brand': { image: './dev4Agriculture.png' , text:'dev4Agriculture', width:'120px'},
    'copy': '© 2023 dev4Agriculture All rights reserved.',
    'link1': 'Website',
    'link2': 'Imprint',
    'link3': 'Data Privacy Statement'
  };

  let brand;

  if (content.brand.image) {
    brand = <img src={ content.brand.image } alt="" width={ content.brand.width } />;
  } else {
    brand = content.brand.text || '';
  }

  return (
        <FooterContainer>
            <Grid container justifyContent="flex-start">
            <Link sx={{ px:3}} href="https://www.dev4Agriculture.de" color="inherit" underline="none">
                Presented by {brand}
            </Link>
            </Grid>
            <Grid container justifyContent="flex-center">
                <Link sx={{ px:3}} href="https://www.dev4Agriculture.de" variant="body1" color="textPrimary" >{content['link1']}</Link>
                <Link sx={{ px:3}} href="https://www.dev4agriculture.de/en/imprint/" variant="body1" color="textPrimary" >{content['link2']}</Link>
                <Link sx={{ px:3}} href="https://www.dev4agriculture.de/en/data-privacy-policy/" variant="body1" color="textPrimary" >{content['link3']}</Link>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Typography right={40} color="textSecondary" component="p" variant="caption" gutterBottom={false}>{content['copy']}</Typography>
            </Grid>
        </FooterContainer>
    );
}