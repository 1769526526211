import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import AuthService from "../auth-config/auth-service";
import { isInitializedSelector } from "../state/authSlice";

interface LoginRequiredProps {
  children: any;
}
export default function LoginRequired({ children }: LoginRequiredProps) {
  const isInitialized = useSelector(isInitializedSelector);

  // @ts-ignore AuthService.getInstance() is null
  const { isLoggedIn, login } = AuthService.getInstance();

  if (!isInitialized || !isLoggedIn) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6">This tool requires authorization</Typography>
        <Button sx={{ mt: 2 }} size="large" variant="contained" onClick={login}>
          Please login
        </Button>
      </Box>
    );
  }

  return children;
}
