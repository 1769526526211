import React, { ChangeEvent, useCallback } from "react";
import { Badge, Box, Grid, Tab, Tabs } from "@mui/material";

import { DeviceAnalysisResult, DeviceDTO, ResponseWithMessages } from "../../orm/dto";
import LoginRequired from "../LoginRequired";
import ResponseMessages from "../ResponseMessages";
import DeviceOverviewGraph from "./DeviceOverviewGraph";
import DeviceElements from "./DeviceElements";
import DeviceDetails from "./DeviceDetails";
import DeviceInput from "./DeviceInput";
import InfoBox from "../Common/InfoBox";
import DeviceDescriptionHelp from "../help/DeviceDescriptionHelp";

interface TabPanelProps {
  children?: React.ReactNode
  tab: string
  value: string,
  [prop: string]: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, tab, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== tab}
            {...other}
        >
            {value === tab && children}
        </div>
    )
}

export default function DeviceDescription() {
    const [currentTab, setCurrentTab] = React.useState('input')

    const [deviceXML, setDeviceXML] = React.useState('')
    const [analysisResponse, setAnalysisResponse] = React.useState<ResponseWithMessages<DeviceAnalysisResult> | null>(null)

    const onTabChange = useCallback((event: React.SyntheticEvent, newTabValue: string) => {
        setCurrentTab(newTabValue)
    }, [])

    const onDeviceXMLChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDeviceXML((event.target as HTMLInputElement).value)
    }, [])

    const onAnalysisResponseChange = useCallback((result: ResponseWithMessages<DeviceAnalysisResult> | null) => {
        setAnalysisResponse(result)
        result && setCurrentTab(result.result ? 'overview' : 'report')
    }, [])

    return (
        <LoginRequired>
            <Grid container wrap="nowrap" sx={{ height: 1}} spacing={2}>
                <Grid item xs={8} sx={{ height: 1}} container direction="column" spacing={2}>
                    <Box display="flex" flexDirection="column" height="100%">
                        <Box sx={{ width: '100%'}}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={currentTab} onChange={onTabChange} aria-label="basic tabs example">
                                    <Tab label="Device Description XML" value="input"/>
                                    <Tab
                                        label={
                                            <Badge color="primary" badgeContent={analysisResponse?.messages?.length || 0}>
                                                <Box sx={{pr: 1}}>Report</Box>
                                            </Badge>
                                        }
                                        value="report"
                                        disabled={!analysisResponse}
                                    />
                                    <Tab label="Hierarchy Overview" value="overview" disabled={!analysisResponse}/>
                                    <Tab label="Device Data" value="data" disabled={!analysisResponse}/>
                                    <Tab label="Device Details" value="details" disabled={!analysisResponse}/>
                                </Tabs>
                            </Box>
                        </Box>
                        <TabPanel value={currentTab} tab="input" style={{flexGrow: 1}}>
                            <DeviceInput
                                deviceXML={deviceXML}
                                onDeviceXMLChange={onDeviceXMLChange}
                                onAnalysisResponseChange={onAnalysisResponseChange}
                            />
                        </TabPanel>
                        <TabPanel value={currentTab} tab="report" style={{flexGrow: 1, paddingTop: 16}}>
                            {analysisResponse?.messages && (
                                <ResponseMessages messages={analysisResponse.messages} />
                            )}
                        </TabPanel>
                        <TabPanel value={currentTab} tab="overview" style={{flexGrow: 1, position: 'relative'}}>
                            {analysisResponse?.result?.hierarchy && (
                                <DeviceOverviewGraph overviewGraph={analysisResponse.result.hierarchy} />
                            )}
                        </TabPanel>
                        <TabPanel value={currentTab} tab="data" style={{flexGrow: 1, height: 0, overflowY: 'auto'}}>
                            {analysisResponse?.result?.elements && (
                                <DeviceElements
                                    deviceElements={analysisResponse.result.elements}
                                    device={analysisResponse.result.device as DeviceDTO}
                                />
                            )}
                        </TabPanel>
                        <TabPanel value={currentTab} tab="details" style={{marginTop: 16, flexGrow: 1, height: 0, overflowY: 'auto'}}>
                            {analysisResponse?.result?.device && (
                                <DeviceDetails device={analysisResponse.result.device} />
                            )}
                        </TabPanel>
                    </Box>
                </Grid>
                <InfoBox>
                    <DeviceDescriptionHelp/>
                </InfoBox>
            </Grid>
        </LoginRequired>
    )
}