import React from "react";
import Alert, { AlertColor } from "@mui/material/Alert/Alert";
import { ResponseMessage } from "../orm/dto";

interface ResponseMessagesProps {
    messages: ResponseMessage[]
}
export default function ResponseMessages({messages}: ResponseMessagesProps) {
    function ToSeverity(message: ResponseMessage):AlertColor{
        if( message.type === "Error"){
            return 'error';
        } else if( message.type === "Warning"){
            return 'warning';
        } else {
            return 'info';
        }
    }
    
    return (<>
        {messages.map((message, idx) => (
            <Alert key={idx} sx={{mb: 1}} severity={ToSeverity(message)}>{message.title} - {message.description}</Alert>
        ))}
    </>)
}