import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import logo from '../logo.svg'
import { useNavigate } from 'react-router-dom'

interface ButtonCardProps {
    title: string
    description: string
    url: string
}

const ButtonCard = ({url, title, description}: ButtonCardProps) => {
    const navigate = useNavigate()
    const onClick = useCallback(() => {
        navigate(url)
    }, [navigate, url])

    return (
        <Button
            onClick={onClick}
            sx={{m: 2, width: 220, height: 200, flexDirection: 'column', justifyContent: 'flex-start'}}
            variant="contained"
            color="primary"
        >
            <Typography variant="h6" mt={2}>{title}</Typography>
            <Typography sx={{mt: 4}}>{description}</Typography>
        </Button>
    )
}

const LinkButtonCard = ({url, title, description}: ButtonCardProps) => {
    const onClick = ()=> { window.open(url,"_blank")};
    return (
        <Button
            onClick={onClick}
            sx={{m: 2, width: 220, height: 200, flexDirection: 'column', justifyContent: 'flex-start'}}
            variant="contained"
            color="primary"
        >
            <Typography variant="h6" mt={2}>{title}</Typography>
            <Typography sx={{mt: 4}}>{description}</Typography>
        </Button>
    )
}

export default function Index() {
    return (
        <Box sx={{textAlign: 'center'}}>
            <Box sx={{m: 4, height: 200}} component="img" src={logo} alt="Logo" />
            <Box>
                <ButtonCard url='/date-time' title="Date &amp; Time" description="Date time conversions specifically for ISOXML" />
                <ButtonCard url='/pgn' title="PGN" description="Parse and construct Parameter Group Number" />
                <ButtonCard url='/clientName' title="ClientName" description="Parse or construct any ISOClientName" />
                <ButtonCard url='/dvc' title="Device" description="Parse ISOXML Device Description" />
                <LinkButtonCard url='https://isoxml-viewer.dev4ag.com' title="ISOXML Viewer" description="Display uploaded ISOXML in the browser" />
                <ButtonCard url="/isoxml-check" title="ISOXML Validation" description='Upload an ISOXML and get a list of errors and warnings' />
                <ButtonCard url="/isoxml-finisher" title="ISOXML Finisher" description='Upload an initial ISOXML, select a machine and simulate the work'/>
                <ButtonCard url="/shape2isoxml" title="Shape To ISOXML" description="Convert Shape or GeoJSN \n into ISOXML" />
            </Box>
        </Box>
    )

    
}