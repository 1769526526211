import React, { useCallback } from 'react'

import User from '../User'
import { useNavigate } from 'react-router-dom'
import { AppBar, Box, Button, IconButton, styled, Toolbar } from '@mui/material'
import { Home } from '@mui/icons-material'

const HeaderMenuItem = styled(Button)({
    color: 'white',
})

export default function Header() {

    const navigate = useNavigate()

    const onMenuItemClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        const url = event.currentTarget.dataset['url'] as string
        navigate(url)
    }, [navigate])

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton data-url="/" onClick={onMenuItemClick}>
                    <Home fontSize="large" sx={{color: 'white'}} />
                </IconButton>
                <Box sx={{ flexGrow: 1, display: 'flex', mx: 2 }}>
                    <HeaderMenuItem data-url="/date-time" onClick={onMenuItemClick}>
                        Date&amp;Time
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/pgn" onClick={onMenuItemClick}>
                        PGN
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/clientName" onClick={onMenuItemClick}>
                        ISOClientName
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/dvc" onClick={onMenuItemClick}>
                        Device
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/" onClick={() => window.open("https://isoxml-viewer.dev4ag.com","_blank")}>
                        ISOXML Viewer
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/isoxml-check" onClick={onMenuItemClick}>
                        ISOXML Checks
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/isoxml-finisher" onClick={onMenuItemClick}>
                        ISOXML Finisher
                    </HeaderMenuItem>
                    <HeaderMenuItem data-url="/shape2isoxml" onClick={onMenuItemClick}>
                        Shape 2 ISOXML
                    </HeaderMenuItem>
                </Box>
                <User/>
            </Toolbar>
        </AppBar>
    )


}