import {
    Button,
    Checkbox,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    styled, Switch,
    TableCell,
    tableCellClasses,
    TableRow, Tooltip
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useEffect, useState } from 'react'
import { Calculate } from '@mui/icons-material';

type FilteredKeys<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never
 }[keyof T]

type SelectOptions = Array<{
    label: string
    value: string | number
}>

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: '100%',
        border: 0
    },
}));


export interface InputRowProps<T>{
    fields: T,
    title: string,
    attr: FilteredKeys<T, number>,
    value?:number|undefined,
    min: number,
    max: number,
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

export function InputRow<T>({
    fields,
    title,
    attr,
    value,
    min,
    max,
    onChange
}:InputRowProps<T>) {
    let [_value,setValue]= useState<number|undefined>(value);
    useEffect(()=>{
        setValue(value);
    },[value])

    return (
        <TableRow>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell>
                <OutlinedInput
                    fullWidth
                    size="small"
                    type="number"
                    inputProps={{min, max, 'data-bind-attribute': attr, name: attr}}
                    value={_value}
                    onChange={onChange}
                />
            </StyledTableCell>
        </TableRow>
    )
}


export interface InputTextRowProps<T>{
    fields: T,
    title: string,
    attr: FilteredKeys<T, number>,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    disabled?: boolean,
    error?: string
}

export function InputTextRow<T>(
    {fields,
    title,
    attr,
    onChange,
    disabled,
    error}:InputTextRowProps<T>
) {
    return (
        <TableRow>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell style={{ position: 'relative' }}>
                <OutlinedInput
                    fullWidth
                    size="small"
                    type="text"
                    inputProps={{
                        'data-bind-attribute': attr,
                        name: attr,
                        style: {
                            paddingRight: error ? '40px' : '14px'
                        }
                    }}
                    value={fields[attr]}
                    onChange={onChange}
                    disabled={disabled}
                    error={!!error}
                />
                <Tooltip hidden={!error} title={error} style={{ position: 'absolute', right: '16px' }}>
                    {error ? (
                        <IconButton color='error'>
                            <ErrorOutlineIcon/>
                        </IconButton>
                    ) : <></>}
                </Tooltip>
            </StyledTableCell>
        </TableRow>
    )
}

export interface CheckBoxRowProps<T>{
    fields: T,
    title: string,
    attr: FilteredKeys<T, boolean>,
    onChange: React.ChangeEventHandler<HTMLInputElement>
}


export function CheckboxRow<T>(
    {fields,
    title,
    attr,
    onChange}:CheckBoxRowProps<T>
) {
    return (
        <TableRow>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell>
                <Checkbox
                    inputProps={{'data-bind-attribute': attr, name: attr} as any}
                    checked={fields[attr] as any}
                    onChange={onChange}
                />
            </StyledTableCell>
        </TableRow>
    )
}


export interface SwitcherRowProps<T>{
    fields: T,
    title: string,
    attr: FilteredKeys<T, boolean>,
    onChange: React.ChangeEventHandler<HTMLInputElement>

}

export function SwitcherRow<T>(
{
    fields,
    title,
    attr,
    onChange
}: SwitcherRowProps<T>
    ) {
    return (
        <TableRow>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell>
                <Switch
                    inputProps={{'data-bind-attribute': attr, name: attr} as any}
                    checked={fields[attr] as any}
                    onChange={onChange}
                />
            </StyledTableCell>
        </TableRow>
    )
}


export interface SelectRowProps<T>{
    fields: T,
    title: string,
    attr: FilteredKeys<T, boolean>,
    options: SelectOptions,
    onChange: (e: SelectChangeEvent) => void

}

export function SelectRow<T>(
    {
        fields,
        title,
        attr,
        options,
        onChange
    }:SelectRowProps<T>
) {
    return (
        <TableRow>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell>
                <Select
                    fullWidth
                    size='small'
                    inputProps={{'data-bind-attribute': attr, name: attr} as any}
                    value={fields[attr] as any}
                    onChange={onChange}
                >
                    {options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                </Select>
            </StyledTableCell>
        </TableRow>
    )
}


export interface UnitCalculatorRowProps<T>{
    text: string,
    title: string,
    onClickCalculate: (unit:string)=>void,
    disabled?: boolean,
    error?: string
}

export function UnitCalculatorRow<T>(
    {
        text,
        title,
        onClickCalculate,
        disabled,
        error
    }:UnitCalculatorRowProps<T>
){
    let [unit,setUnit] = useState<string>(text);
    function CalculateUnit(){
        onClickCalculate(unit);
    }

    useEffect(()=>{
        setUnit(text);
    },[text])

    return(
        <TableRow>
            <StyledTableCell>{title}<Button onClick={(e)=>{CalculateUnit()}} disabled={disabled}><Calculate/></Button></StyledTableCell>
            <StyledTableCell>
            <OutlinedInput
                    fullWidth
                    size="small"
                    type="text"
                    inputProps={{
                        style: {
                            paddingRight: error ? '40px' : '14px',
                            maxWidth:"60%"
                        }
                    }}
                    value={unit}
                    onChange={(e) =>{
                        setUnit(e.target.value);
                    }
                    }
                    disabled={disabled}
                    error={!!error}
                />
                <Tooltip hidden={!error} title={error} style={{ position: 'absolute', right: '16px' }}>
                    {error ? (
                        <IconButton color='error'>
                            <ErrorOutlineIcon/>
                        </IconButton>
                    ) : <></>}
                </Tooltip>
            </StyledTableCell>
        </TableRow>
    );
}

function setState<T>(text: string): [any, any] {
    throw new Error('Function not implemented.');
}
